<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    app
    dark
    floating
    persistent
    mobile-breakpoint="960"
    width="260"
  >
    <v-img
      :src="image"
      :gradient="sidebarOverlayGradiant"
      height="100%"
    >
      <v-layout
        tag="v-list"
        column
      >
        <v-list>
          <v-list-item class="v-list-app-icon mt-0">
            <v-list-item-title
              class="x-drawer-logo d-flex align-center justify-space-between"
            >
              <v-img
                position="left top"
                :src="logoText"
                contain
                :height="64"
              />
              <div
                v-if="!isProductEnv" 
                class="success--text text--lighten-1 ml-n12"
                style="font-size: 16px;"
              >
              [ Test ]
              </div>
            </v-list-item-title>
          </v-list-item>
          <v-divider/>
          <v-list-item class="v-list-app-user mb-2">
            <v-avatar
              size="34"
              class="mr-4"
            >
              <v-img
                :src="userIcon"
              />
            </v-avatar>
            <v-list-item-content>
              {{ userName }}
            </v-list-item-content>
          </v-list-item>
          <v-divider class="x-divider-before-menu"/>
          <template v-for="(route, i) in menu">
            <v-list-item
              :key="'title_' + i"
              :to="(route.alias && !!route.alias.find(el => $route.path.indexOf(el) > -1) ? $route.path : '') || route.path"
              @click="clickTitle(route, i)"
              active-class="selected"
              :class="{
                'v-list-item v-menu-title': true
              }"
            >
              <v-list-item-action>
                <v-icon>{{ route.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-title
                v-text="route.name"
              />
              <v-list-item-action class="mr-0">
                <v-icon v-show="!!route.children">{{ expandTitle === i ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-expand-transition :key="'title_expand_' + i">
              <div v-if="!!route.children && expandTitle === i" class="sub-title-container mt-2 mb-2">
                <template v-for="(child, ci) in route.children">
                  <v-list-item
                    :key="'sub_title_' + ci"
                    :class="['v-list-item v-menu-sub-title text--lighten-1', ...(child.alias && !!child.alias.find(el => $route.path.indexOf(el) > -1) ? ['selected'] : ['grey--text'])]"
                    :to="child.path"
                    active-class="selected"
                  >
                    <v-list-item-title
                      v-text="child.name"
                      class="text-caption"
                    />
                  </v-list-item>
                </template>
              </div>
            </v-expand-transition>
          </template>
        </v-list>
      </v-layout>
    </v-img>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapMutations, mapState } from 'vuex'
import { isProductEnv } from '@/config'

export default {
  props: {
    user: {
      type: Object,
      required: true
      // example:
      // {
      //   name: '',
      //   icon: ''
      // }
    },
    menu: {
      type: Array,
      required: true
      // example:
      // [
      //   {
      //     name: 'Text displayed',
      //     icon: 'Icon displayed',
      //     path: 'Route path'
      //     children: [
      //       name: 'Text displayed',
      //       path: 'Route path'
      //     ]
      //   }
      // ]
    }
  },
  data: () => ({
    logo: require('@/static/img/icons/login-no-bg.png'),
    logoText: require('@/static/img/icons/ginkgo-text.png'),
    image: require('@/static/img/app/bg/login.jpg'),
    defaultUserIcon: require('@/static/img/avatars/default.jpg'),
    expandTitle: null
  }),
  computed: {
    ...mapState('app', ['drawer']),
    sidebarOverlayGradiant () {
      return 'rgba(27, 27, 27, 0.74), rgba(27, 27, 27, 0.74)'
    },
    isProductEnv () {
      return isProductEnv
    },

    inputValue: {
      get () {
        return this.drawer
      },
      set (val) {
        this.setDrawer(val)
      }
    },
    userName() {
      return this.user.name || 'Loading'
    },
    userIcon() {
      return this.user.icon || (this.user.name ? this.defaultUserIcon : '')
    }
  },
  methods: {
    ...mapMutations('app', ['setDrawer']),
    clickTitle (route, i) {
      this.expandTitle = (this.expandTitle === i) ? null : i
    }
  }
}
</script>

<style lang="scss">
  #app-drawer {
    .v-list-item {
      border-radius: 4px;

      &--buy {
        margin-top: auto;
        margin-bottom: 17px;
      }
    }

    .v-image__image--contain {
      top: 9px;
      height: 60%;
    }

    .search-input {
      margin-bottom: 30px !important;
      padding-left: 15px;
      padding-right: 15px;
    }

    div.v-responsive.v-image > div.v-responsive__content {
      overflow-y: auto;
    }

    .v-menu-title,
    .v-menu-sub-title {
      cursor: pointer;
    }

    .sub-title-container {
      margin-left: 32px;
      border-left: 1px solid rgba(255,255,255,.4);
    }

    .v-menu-title {
      .selected {
        background-color: hsla(0,0%,78%,.4);
      }
    }

    .v-menu-sub-title {
      margin: 1px 15px 3px 12px;

      .v-list-item {
        margin: 0 6px 0 0;
        padding: 2px 12px 2px 15px;
        height: auto;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;

        .v-list-item__title {
          font-size: 13px !important;
        }
      }
      
      .selected {
        background-color: hsla(0,0%,78%,.32);
        color: white;
      }
      
      &.selected {
        .v-list-item.v-list-item--link {
          background-color: hsla(0,0%,78%,.32);
          color: white;
        }
      }
    }
  }

  .v-list-app-icon {
    margin: 3px 0;

    .v-list-item__content {
      font-size: 14px !important;
    }

    .v-list-item__title {
      margin-top: 9px;
      margin-left: -24px;
    }
  }

  .v-list-app-user {
    margin: 0 28px;

    .v-list-item__content {
      font-size: 14px !important;
      font-weight: 100 !important;
    }
  }

  .x-divider-before-menu {
    margin-bottom: 14px !important;
  }

  .x-drawer-logo {
    .v-avatar {
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
    }
  }
</style>
