import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import xss from 'xss'
import _ from 'lodash'

import '@/assets/scss/index.scss';
import '@/assets/icons/iconfont.css'                                    // iconfont by ali

Vue.config.productionTip = false

Vue.prototype.$eventBus = new Vue()
Vue.prototype.xss = xss
Vue.prototype._ = _

import '@/components/theme/components'

Vue.use(VueAxios, axios)
xss
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')