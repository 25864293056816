export const state = () => {
  return {
    // device
    agent: '',
    device: 'mobile',
    mainTitle: '',

    // window
    windowSize: {
      width: 0, //px
      height: 0  //px
    },

    // loading
    loading: '',

    drawer: null,

    // theme
    color: {
      default: '#000',
      primary: '#aba9e9'
    },

    log: [],
  };
}