// project-dev-to-live - pro/test/crv/dev
const programEnv = 'pro'
// const programEnv = 'tes'
// const programEnv = 'crv'
// const programEnv = 'dev' // only available when try to use local php/db


export const isDevEnv = (programEnv === 'dev')
export const isTestEnv = (programEnv === 'tes')
export const isProductEnv = (programEnv === 'pro')
export const isCdRevEnv = (programEnv === 'crv')

export const devRootUrl = 'http://localhost:8081'
export const tesRootUrl = 'https://pharos.nielseniq.cn'
// export const tesRootUrl = 'https://pharos-test.nielseniq.com'
export const proRootUrl = 'https://ginkgo.nielseniq.cn'
export const cdRevRootUrl = 'https://pharos.nielseniq.cn'
// export const cdRevRootUrl = 'https://pharos-test.nielseniq.com'

export const subPath = (isProductEnv || isDevEnv) ? '' : (isCdRevEnv ? 'ginkgo_codereview' : 'ginkgolavastest')

// url
export const devRedirUrl = devRootUrl + (subPath ? '/' + subPath : '')
export const tesRedirUrl = tesRootUrl + (subPath ? '/' + subPath : '')
export const proRedirUrl = proRootUrl + (subPath ? '/' + subPath : '')
export const cdRevRedirUrl = cdRevRootUrl + (subPath ? '/' + subPath : '')
export const redirUrl =
  (isProductEnv ? proRedirUrl : (
  isCdRevEnv ? cdRevRedirUrl : (
  isTestEnv ? tesRedirUrl : devRedirUrl)))
    + '/login'

export const reqUrl = (
  process.env.NODE_ENV === 'production'
  ? (
    isProductEnv ? 
    '/api/ginkgo' : 
    (
      isCdRevEnv ? 
      '/api/ginkgo_codereview' :
      // 'https://pharos.nielseniq.cn/api/pharos/ginkgo_codereview' :
      ('/api/pharos/' + subPath)
    )
  )
  : (
    isDevEnv
    ? devRootUrl + '/post'
    : ('/api/pharos/' + subPath)
  )
) + '/index.php'




// color
export const section_color = {
  'default': 'primary',
  'wbs': 'wbs',
  'approval': 'approval',
  'admin': 'grey darken-4',
}



// mylab
const lab_staff = [
  'kyle.huang@nielseniq.com',
  'robin.z.zhou@nielseniq.com'
]

export const isInLab = (email) => {
  return (lab_staff.indexOf(email) > -1)
}

// test version
export const prodVersion = '4.1.30'