import { getCookie } from '@/functions/cookie'

export const state = () => {
  return {
    myRoutePath: getCookie('my_route_path'),

    msal: {
      app: null,
      config: {
        scopes: ['user.read'],
        // scopes: ['User.Read', 'User.Read.All', 'People.Read'],
        clientId: '637c2e1b-3c98-422c-9de6-8d56c7442697',
        tenantId: '6ac7a1f4-5fb1-4153-bb4f-12d2020a1f7d',
      },
      onedriveId: null,
      account: {
        email: null,
        name: null,
        homeId: null,
        accTok: null,
      },
      api: 'https://graph.microsoft.com/beta',
    },

    info: {
      photoUrl: null,
      team: null,
      location: null,
      costCenter: null,
    },

    listUsers: [],
  };
}