/**
 * @file for user
 * @author Robin Zhou
 */

// import { state as stateJs } from './user/state.js'
// import { getters as gettersJs } from './user/getters.js'
// import { mutations as mutationsJs } from './user/mutations.js'
// import { actions as actionsJs } from './user/actions.js'

// export const state = stateJs
// export const getters = gettersJs
// export const mutations = mutationsJs
// export const actions = actionsJs

import { state } from './user/state.js'
import { getters } from './user/getters.js'
import { mutations } from './user/mutations.js'
import { actions } from './user/actions.js'

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}