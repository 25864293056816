import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

const allTheme = {
  "primary": "#aba9e9",
  "wbs": "#5b86c1",
  "approval": "#db7093",
  "btn-color-default": "#aba9e9",
  "color-toolbar-btn": colors.grey.darken2,
}

export default new Vuetify({
  theme: {
    themes: {
      light: allTheme,
      dark: allTheme,
    },
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
});
