export const getters = {
  loginStatus: state => ({
    userInfo: {
      ...state.msal.account,
      primaryEmail: state.msal.account.email,
      name: {
        fullName: state.msal.account.name,
      },
    },
    userName: state.msal.account.name,
    runAs: {
      key: '',
      name: '',
      email: '',
      originalEmail: ''
    },
  }),
  userEmail: state => state.msal.account.email,
  userName: state => state.msal.account.name,
  userPhotoUrl: state => state.info.photoUrl,
  userTeam: state => state.info.team,
  userLocation: state => state.info.location,
  costCenter: state => state.info.costCenter,
  userInfo: state => ({
    ...state.info,
    email: state.msal.account.email,
    name: {
      fullName: state.msal.account.name,
    },
  }),

  listUser: state => email => {
    try {
      return state.listUsers.find(el => el.email === email)
    } catch (e) {
      return null
    }
  }
}