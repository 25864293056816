export const actions = {
  setDevice({ commit }, data) {
    commit('SET_DEVICE', data)
  },

  setLoading({ commit }, data) {
    commit('SET_LOADING', data)
  },

  setWindowSize({ commit }, data) {
    commit('SET_WINDOW_SIZE', data)
  },

  setMainTitle({ commit }, data) {
    commit('SET_MAIN_TITLE', data)
  },

  addLog({ commit }, data) {
    commit('ADD_LOG', data)
  },
}