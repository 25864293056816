<template>
  <v-toolbar
    id="core-toolbar"
    flat
    prominent
    class="mb-0"
    style="background:#eee;height:90px"
  >
    <v-toolbar-title
      class="my-title--text mt-1 font-weight-light align-self-start d-flex align-center"
      style="height:50px"
    >
      <v-btn
        v-if="responsive"
        v-on="on"
        class="color-toolbar-btn--text v-btn--simple ma-0"
        dark
        icon
        @click.stop="onClickBtn"
      >
        <v-icon>mdi-view-list</v-icon>
      </v-btn>
      <div class="mx-2">{{ title }}</div>
    </v-toolbar-title>

    <v-spacer />

    <v-toolbar-items>
      <v-flex
        align-center
        layout
        py-2
        style="height:50px"
      >
        <slot name="extra"></slot>
      </v-flex>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>

import {
  mapMutations
} from 'vuex'

export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    notifications: [
      'Mike, John responded to your email',
      'You have 5 new tasks',
      'You\'re now a friend with Andrew',
      'Another Notification',
      'Another One'
    ],
    responsive: false
  }),

  mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },

  methods: {
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    onClickBtn () {
      this.setDrawer(!this.$store.state.app.drawer)
    },
    onClick () {
      //
    },
    onResponsiveInverted () {
      if (window.innerWidth < 991) {
        this.responsive = true
      } else {
        this.responsive = false
      }
    }
  }
}
</script>

<style>
  #core-toolbar a {
    text-decoration: none;
  }
</style>
