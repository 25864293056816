import { setCookie, rmvCookie } from '@/functions/cookie'

const setUserInfo = 'SET_USER_INFO'
const setMyRoutePath = 'SET_MY_ROUTE_PATH'
const setMsalApp = 'SET_MSAL_APP'
const setMsalAccount = 'SET_MSAL_ACCOUNT'
const setInfo = 'SET_INFO'
const setOnedriveId = 'SET_ONEDRIVE_ID'

const setListUser = 'SET_LIST_USER'

export const mutations = {
  [setUserInfo](state, data) {
    state.userInfo = {
      ...{
        addresses: [],
        customSchemas: { CustomAttributes: '' },
        emails: [],
        etag: '',
        externalIds: [],
        gender: { type: '' },
        id: '',
        kind: '',
        name: { givenName: '', familyName: '', fullName: '' },
        organizations: [{ department: '' }],
        phones: [],
        primaryEmail: '',
        relations: [{ value: '' }],
        thumbnailPhotoEtag: '',
        thumbnailPhotoUrl: ''
      },
      ...data
    }
  },

  [setMyRoutePath](state, data) {
    state.myRoutePath = data
    setCookie('my_route_path', data)
  },

  [setMsalApp](state, data) {
    data = data || null
    state.msal.app = data
  },

  [setMsalAccount](state, data) {
    data = data instanceof Object ? data : {}
    for (const key of Object.keys(state.msal.account)) {
      if (data[key] === undefined) { continue }
      state.msal.account[key] = data[key]
    }
  },

  [setOnedriveId](state, data) {
    state.msal.onedriveId = data || null
  },

  [setInfo](state, data) {
    data = data instanceof Object ? data : {}
    for (const key of Object.keys(state.info)) {
      if (data[key] === undefined) { continue }
      state.info[key] = data[key]
    }
  },

  [setListUser](state, data) {
    try {
      if (!(
        (data instanceof Object)
        && data.email
        && (data.info instanceof Object)
      )) { return }
      let indexData = state.listUsers.findIndex(el => el.email === data.email)
      if (indexData === -1) {
        indexData = state.listUsers.length
        state.listUsers.push(JSON.parse(JSON.stringify(data)))
      }
      state.listUsers[indexData].info = JSON.parse(JSON.stringify({
        ...state.listUsers[indexData].info,
        ...data.info,
      }))
    } catch (e) {}
  }
}