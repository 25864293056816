/**
 * @file for app
 * @author Robin Zhou
 */

// import { state as stateJs } from './app/state.js'
// import { getters as gettersJs } from './app/getters.js'
// import { mutations as mutationsJs } from './app/mutations.js'
// import { actions as actionsJs } from './app/actions.js'

// export const state = stateJs
// export const getters = gettersJs
// export const mutations = mutationsJs
// export const actions = actionsJs

import { state } from './app/state.js'
import { getters } from './app/getters.js'
import { mutations } from './app/mutations.js'
import { actions } from './app/actions.js'

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}