
const setDevice = 'SET_DEVICE'
const setLoading = 'SET_LOADING'
const setWindowSize = 'SET_WINDOW_SIZE'
const setMainTitle = 'SET_MAIN_TITLE'
const addLog = 'ADD_LOG'

import { set, toggle } from '../mutationFunctions.js'

export const mutations = {
  [setDevice](state, data) {
    state.agent = data
    let agents = ['android', 'iphone', 'symbianOS', 'windows phone', 'ipad', 'ipod']
    state.device = agents.findIndex(el => data.toLowerCase().indexOf(el) > -1) > -1 ? 'mobile' : 'pc'
  },

  [setLoading](state, data) {
    state.loading = data
  },

  [setWindowSize](state, data) {
    state.windowSize.width = data.width
    state.windowSize.height = data.height
  },

  [setMainTitle](state, data) {
    state.mainTitle = data
  },

  [addLog](state, data) {
    state.log.push(data)
  },

  setDrawer: set('drawer'),
  toggleDrawer: toggle('drawer'),
}