<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
  beforeCreate () {
    document.title = 'Ginkgo'
  },
};
</script>

<style>
  /* scroll bar */
  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius   : 10px;
  background-color: rgba(170, 170, 170, 1);
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(210, 210, 210, 1) 25%,
    transparent 25%,
    transparent 50%,
    rgba(210, 210, 210, 1) 50%,
    rgba(210, 210, 210, 1) 75%,
    transparent 75%,
    transparent
  );
  }
  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0);
    background   : rgba(245, 245, 245, 1);
    border-radius: 10px;
  }
</style>
