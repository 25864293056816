<template>
  <material-card
    v-bind="$attrs"
    class="v-card--material-app mb-0"
    v-on="$listeners"
  >
    <div slot="offset" class="v-card--material-app-title-container">
      <v-card
        :class="`elevation-${elevation} v-card--material-app-title-icon`"
        :color="color"
        class="pa-3"
        dark
      >
        <v-icon
          size="33"
        >
          {{ icon }}
        </v-icon>
      </v-card>
      <div class="v-card--material-app-title-content">
        <h3
          class="title display-1 my-title--text font-weight-light">
          {{ title }}
        </h3>
      </div>
      <v-spacer v-if="$slots.extra"></v-spacer>
      <div class="v-card--material-app-title-extra">
        <slot name="extra">
        </slot>
      </div>
    </div>

    <div slot>
      <slot></slot>
    </div>
  </material-card>
</template>

<script>
import Card from './Card'

export default {
  inheritAttrs: false,

  props: {
    ...Card.props,
    icon: {
      type: String,
      required: true
    },
    subIcon: {
      type: String,
      default: undefined
    },
    subIconColor: {
      type: String,
      default: undefined
    },
    subTextColor: {
      type: String,
      default: undefined
    },
    subText: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    }
  }
}
</script>

<style lang="scss">
.v-card--material-app {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  .v-offset {
    display: inline-block;
    flex: 1 1;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0 !important;
    max-width: auto;
    padding: 0 16px 0;
  }

  .v-card {
    border-radius: 4px;
    flex: 0 1 auto;
  }

  .v-card__text {
    display: inline-block;
    width: 100%;
  }

  .v-card__actions {
    flex: 1 0 100%;
  }

  .v-card--material-app-title-container {
    display: flex;
    align-items: flex-start;

    .v-card--material-app-title-icon {
      margin-right: 16px;
      z-index: 2;
    }

    .v-card--material-app-title-content {
      text-align: left;
      margin-top: 30px;

      .title {
        position: absolute;
        margin-top: 6px !important;
      }
    }

    .v-card--material-app-title-extra {
      text-align: right;
      margin: 30px 0 5px 20px;

      .v-btn {
        margin: 0 1px;
      }
    }
  }
}
</style>
