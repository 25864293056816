/**
 * @file cookie config & functions
 * @author Robin Zhou
 */
import Cookie from 'js-cookie'
import { isProductEnv, isCdRevEnv, subPath } from '@/config'

const COOKIE_EXPIRES_DAYS = 30 // cookie expires days
const TEXT_TEST = isProductEnv ? '' : (isCdRevEnv ? 'cdrev_' : 'test_')
const PATH = subPath + (!subPath ? '' : '/')

export const setCookie = (key, value, expireDays) => {
  try {
    Cookie.set('ginkgo__' + TEXT_TEST + key, value, { path: '/' + PATH, expires: expireDays || COOKIE_EXPIRES_DAYS })
    return true
  } catch (e) {
    return false
  }
}

export const getCookie = key => {
  const value = Cookie.get('ginkgo__' + TEXT_TEST + key)
  if (value) return value
  else return ''
}

export const rmvCookie = key => {
  try {
    Cookie.remove('ginkgo__' + TEXT_TEST + key, { path: '/' + PATH })
    return true
  } catch (e) {
    return false
  }
}