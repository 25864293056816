export const actions = {
  setMyRoutePath({ commit }, data) {
    commit('SET_MY_ROUTE_PATH', data || '')
  },

  setUserInfo({ commit }, data) {
    commit('SET_USER_INFO', data)
  },

  setMsalApp({ commit }, data) {
    commit('SET_MSAL_APP', data)
  },

  setMsalAccount({ commit }, data) {
    commit('SET_MSAL_ACCOUNT', data)
  },

  setInfo({ commit }, data) {
    commit('SET_INFO', data)
  },

  setListUser({ commit }, data) {
    commit('SET_LIST_USER', data)
  },

  setOnedriveId({ commit }, data) {
    commit('SET_ONEDRIVE_ID', data)
  },
}